import * as React from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { cn } from '#app/utils/misc.tsx'

const Popover = PopoverPrimitive.Root
const PopoverTrigger = PopoverPrimitive.Trigger

interface PopoverContentProps
	extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> {
	/**
	 * If true, render content directly instead of using a Portal,
	 * allowing it to stay within a parent container like a Sheet.
	 */
	isInsideSheet?: boolean
}

const PopoverContent = React.forwardRef<
	React.ElementRef<typeof PopoverPrimitive.Content>,
	PopoverContentProps
>((props, ref) => {
	const {
		className,
		align = 'center',
		sideOffset = 4,
		isInsideSheet = false, // default: use Portal
		...rest
	} = props

	// the actual content
	const content = (
		<PopoverPrimitive.Content
			ref={ref}
			align={align}
			sideOffset={sideOffset}
			className={cn(
				'z-50 w-72 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none',
				'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0',
				'data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95',
				'data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2',
				'data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
				className,
			)}
			{...rest}
		/>
	)

	// If isInsideSheet is true, do NOT portal
	return isInsideSheet ? (
		content
	) : (
		<PopoverPrimitive.Portal>{content}</PopoverPrimitive.Portal>
	)
})

PopoverContent.displayName = PopoverPrimitive.Content.displayName

export { Popover, PopoverTrigger, PopoverContent }
